<template>
  <div>
    <router-link
      to="/members"
      class="back-link font-weight-regular"
      :class="isDesktop ? 'my-2 ml-n1' : 'black--text white fz-20 py-3 w-100 pl-2 pl-md-0 mb-3'"
    >
      <v-icon size="24px" color="#6170C5">
        navigate_before
      </v-icon>
      {{ isDesktop ? 'Members' : 'Invitation Status' }}
    </router-link>
    <h1
      class="d-none d-md-block black-text fz-28 mb-4 font-weight-semi-bold"
    >Invitation Status</h1>
    <div>
      <v-select
        v-model="status"
        :items="statusOptions"
        item-text="label"
        item-value="value"
        class="pa-0 mb-4"
        style="max-width: 140px; height: 36px;"
        menu-props="offsetY"
        solo
        outlined
        hide-details
        @change="loadInvitations"
      >
        <template v-slot:append>
          <v-icon size="15px">unfold_more</v-icon>
        </template>
      </v-select>
    </div>
    <div>
      <v-data-table
        :headers="computedHeaders"
        :items="invitationList"
        :loading="loading"
        item-key="id"
        hide-default-footer
        disable-sort
      >
        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip v-if="item.status === 'pending'" top content-class="v-tooltip--top">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="mr-1"
                  height="36px"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop.prevent="resend(item)"
                >
                  <v-icon color="#919EAB">replay</v-icon>
                </v-btn>
              </template>
              Resend
            </v-tooltip>
            <v-tooltip v-if="item.status === 'pending'" top content-class="v-tooltip--top">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  height="36px"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop.prevent="openCancelDialog(item)"
                >
                  <v-icon color="#919EAB">delete</v-icon>
                </v-btn>
              </template>

              <span>Cancel</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog persistent v-model="dialog.show" max-width="450px">
      <v-card>
        <v-card-title class="font-weight-bold">
          Cancel Invitation
        </v-card-title>
        <v-card-subtitle>
          Are you want to cancel invitation for
          <span class="font-weight-semi-bold">{{ dialog.data.firstName }} {{ dialog.data.lastName }}</span>?
        </v-card-subtitle>
        <!-- <v-card-text>
          <v-radio-group v-model="releaseRadio" mandatory>
            <v-radio label="Release Now" value="radio-1"></v-radio>
            <v-radio
                label="Release at the expiration date"
                value="radio-2"
              ></v-radio>
          </v-radio-group>
        </v-card-text> -->

        <v-card-actions class="pb-5 pt-0 justify-space-between">
          <v-btn depressed class="text-capitalize" @click="closeCancelDalog">
            No
          </v-btn>
          <v-btn depressed color="primary" class="text-capitalize" @click="submitCancel">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vue-snotify />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'InvitationStatus',
  data: () => ({
    loading: false,
    status: 'pending',
    statusOptions: [
      { label: 'All', value: '' },
      { label: 'Approved', value: 'accepted' },
      { label: 'Rejected', value: 'rejected' },
      { label: 'Pending', value: 'pending' }
    ],
    headers: [
      {
        text: 'First Name',
        align: 'start',
        sortable: false,
        value: 'firstName',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Last Name',
        align: 'start',
        sortable: false,
        value: 'lastName',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Email',
        align: 'start',
        sortable: false,
        value: 'email',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: ['greyBuoyant', 'text-capitalize']
      },
      {
        text: 'Invited On',
        align: 'start',
        sortable: false,
        value: 'createdOn',
        formatter: (x) => (x ? moment(x).format('YYYY-MM-DD HH:mm:ss') : null),
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ],
    invitationList: [],
    total: 0,
    dialog: {
      show: false,
      data: {}
    }
  }),
  computed: {
    computedHeaders () {
      const headers = [...this.headers]
      if (['', 'pending'].includes(this.status)) {
        headers.push({
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: ['text-caption', 'greyBuoyant'],
          cellClass: 'greyBuoyant'
        })
      }
      return headers
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  created () {
    this.loadInvitations()
  },
  methods: {
    ...mapActions(['getInvitationList', 'resendInvitation', 'cancelInvitation']),
    async loadInvitations () {
      try {
        this.loading = true
        this.invitationList = []
        const params = {}
        if (this.status) {
          params.status = this.status
        }
        const { data } = await this.getInvitationList(params)
        if (!data) return
        this.invitationList = data.data.map(item => {
          let status = 'pending'
          if (item.acceptedOn) status = 'accepted'
          else if (item.canceledOn) status = 'canceled'
          else if (new Date(item.expiredOn) < new Date()) status = 'rejected'
          return {
            ...item,
            status
          }
        })
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async resend (item) {
      this.$snotify.notifications = []
      try {
        const { data } = await this.resendInvitation(item.uuid)
        if (!data) return

        this.$snotify.success('Invitation has been sent successfully')
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    openCancelDialog (item) {
      this.dialog.data = item
      this.dialog.show = true
    },
    closeCancelDalog () {
      this.dialog.show = false
      this.dialog.data = {}
    },
    async submitCancel () {
      this.$snotify.notifications = []
      try {
        const { data } = await this.cancelInvitation(this.dialog.data.uuid)
        if (!data) return

        this.$snotify.success('Invitation has been canceled')
        this.closeCancelDalog()
        this.loadInvitations()
      } catch (err) {
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    }
  }
}
</script>
